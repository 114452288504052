@use "src/styles/common/variables";

.rental {
	max-width:250px;
	.rental-poster {
		margin-bottom: 0px;
	}

	.rental-info {
		padding: 0px 20px;

		.rental-info-name {
			font-weight: bold;
		}

		.rental-info-rented-on {
			margin: 0;

			&.--with-space {
				margin-bottom: 15px;
			}
		}
	}
}

.watermark {
	position: absolute;
	bottom: 60%; /* Adjust to your desired location */
	right: 50%; /* Adjust to your desired location */
	transform: translate(50%, 50% );
	font-size: 3em; /* Size of the watermark text */
	color: rgba(255, 255, 255, 0.7); /* White with transparency */
	background-color: rgba(0, 0, 0, 0.5); /* Subtle background to make it readable */
	padding: 5px 10px; /* Padding around the text */
	border-radius: 5px; /* Optional for rounded edges */
	z-index: 1000000000000; /* Ensure it appears above other elements */
  }
  

.audio-player {
	display: block;
	margin: 10px;
	width: 100%;
	z-index: 1000;
	position: relative;
	bottom: 10px;
	// right: 5%;
	// margin-right: auto;
}
.audio-player-fullscreen {
	display: block;
	margin: 10px auto;
	width: 90%;
	z-index: 1000;
	position: fixed;
	bottom: 10px;
	right: 5%;
	// margin-right: auto;
}

.rental.isSelected {
	max-width: 10000px;
	width: 100%;
	position: relative;
	height: 80%;
	z-index: 0;
}

.rental.isSelected img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.rental:not(.isSelected) {
	margin-bottom: 10px;
}
a {
	text-decoration: none;
	color: inherit;
}

* {
	box-sizing: border-box;
}

.menu {
	display: block;
	position: relative;
}

.menu.isSelected .menu-title:after {
	border-bottom-width: 5px;
	transition: border-bottom-width 0.5s ease-out;
}

.menu-content {
	padding: 0;
	z-index: 99;
	transition: max-height 0.7s ease-in-out, padding 0.7s, background 0.7s;
	max-height: 0;
	overflow: hidden;
	visibility: hidden;
}

.menu.isSelected .menu-content {
	padding: 5px 0;
	max-height: 200px;
	visibility: visible;
}

.menu.isSelected .menu-content:after {
	border-top-color: #ebebeb;
}

.menu-content > * {
	height: 0;
	padding: 0;
	margin: 0;
	color: white;
	transition: height 0.5s cubic-bezier(0.73, 0.32, 0.34, 1.5), padding 0.5s cubic-bezier(0.73, 0.32, 0.34, 1.5),
		margin 0.5s cubic-bezier(0.73, 0.32, 0.34, 1.5), color 0.5s ease-in-out;
}

.menu.isSelected .menu-content > * {
	height: 100%;
	width: 100%;
	padding: 5px 10px;
	margin: 5px 0;
	color: white;
}

// .aframe-container {
// 	position: relative;
// 	bottom: 50px;
// 	left: 10px;
// 	width: 95%;
// 	display: inline-flex;
// 	flex-wrap: wrap;
// }

.aframe-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px 0px;
	position: relative;
	margin: 40px 0px;
	top: -20px;

	@media (max-width: 560px) {
		top: -16px;
		display: inline-block;
	}
	@media (max-width: 400px) {
		top: 20px;
		display: inline-block;
	}
}

.seperate-buttons {
	margin: 5px 10px !important;
	border: none;
	// padding: 0px 10px;
}

.seperate-buttons-for-div {
	margin-left: 14px !important;
	display: inline-flex;
	position: relative;
	top: 5px;
	// align-items: center;
}

.call-to-action-fullscreen {
	position: fixed;
	// top: 0;
	// left: 0;
	right: 10px;
	bottom: 90px;
	margin-left: 14px !important;
	display: inline-flex;
	z-index: 1000;
}

.call-to-action {
	margin: 14px 10px !important;
	display: inline-flex;
	z-index: 10;
}

.call-to-action-buttons {
	// margin: 0px 10px;
	cursor: pointer;
	z-index: 10;
	max-width: 250px;

	@media (max-width: 768px) {
		max-width: 200px;
	}
}

.fixed-height {
	position: relative;
	bottom: 100px;
}

.liked {
	// font-weight: 100;
	color: red;
}

.notLiked {
	// font-weight: 100;
	color: black;
}

.favorite-image {
	max-height: 20px;
	max-width: 25px;
}

.pnlm-container {
	width: 100% !important;
	height: 100% !important;
}

.fullscreen-for-iphone {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	z-index: 999;
	background-color: #fff;
}

.not-fullscreen-for-iphone {
	width: 101%;
	height: 60vh;
	z-index: 999;
	background-color: #fff;
}

.very-high-z-index {
	position: fixed;
	left: 10px;
	bottom: 90px;
	z-index: 1000;
}

//all the importants are because this is based on a library and its to unsure the styles work properly

.rr--box {
	cursor: pointer !important;
	width: 30px;
	display: inline-flex;
	height: 30px;
	margin-right: 5px;
}

.rr--box svg polygon {
	fill: white !important;
	stroke: #ffd700 !important;
	stroke-width: 2px !important;
	transition: fill 0.25s ease, stroke 0.25s ease !important;
}

.rr--box.rr--on svg polygon {
	fill: #ffd700 !important;
	stroke: #ffd700 !important;
}

.rr--box:focus {
	outline: none !important;
}

.rr--box.rr--on:hover svg polygon,
.rr--box:hover svg polygon {
	fill: #ffd700 !important;
}

.window {
	color: white;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 40%;
	transform: translate(-50%, -50%);
	padding: 20px;
	background-color: rgba(8, 8, 8, 0.9);
	z-index: 100000000;
	@media screen and (min-width: 475px) and (max-width: 820px) {
		width: 70%;
	}

	@media screen and (max-width: 474px) {
		width: 90%;
	}
}

.window-logo {
	max-width: 50%;

	margin: 15px 10px;
}

.line {
	width: 100%;
	height: 20px;
	padding: 9px 0;
	box-sizing: border-box;
	background: white content-box;
}

.title {
	font-size: 1.8em;
	font-weight: bold;
}

.buttons-container {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.save-buttons {
	margin: 10px 15px;
	padding: 5px 15px;
	border: none;
	background-color: black;
	border-radius: 20px;
	color: white;
}


.device-options-container {
	display: flex;
	gap: 1rem;
	justify-content: center;
	flex-wrap: wrap;
	margin: 1rem 0; 
}

.deviceOptions {
	max-width: 200px; /* Ensures images are reasonably sized */
	height: auto;
}