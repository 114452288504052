@use "src/styles/common/variables";

.rentals-dashboard {
  display: block;
  padding: variables.$spacing-xs;
  background-color: rgba(0, 0, 0, 0.75);
  // margin: variables.$spacing-xs;
  // margin-top: 80px;
  padding: variables.$spacing-s;
  margin: 40px auto;
  width: 90%;

  @include variables.mq-for-desktop {

    width: 80%;
  }

  h2 {
    margin-bottom: variables.$spacing-s;
    color: variables.$primary-color;
  }

  h3 {
    margin-bottom: variables.$spacing-s;
    margin-top: variables.$spacing-s;
    color: variables.$primary-color;
  }

  .rentals-dashboard-rentals {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, auto));
    justify-content: center;
    row-gap: variables.$spacing-xs;
    column-gap: variables.$spacing-xs;
    margin-bottom: variables.$spacing-xs;
    color: variables.$primary-color;
  }
}

.rentals-dashboard-rental {
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.rentals-dashboard-rental.isSelected {
  grid-area: 1 / 1 / 2 / 2; 
  z-index: 10;
  grid-column: 1 / -1;
}

.rentals-dashboard-rental.isSelected .menu {
  height: 100%;
}

.rentals-dashboard-rental.isSelected .menu img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@include variables.mq-for-tablet {
  .rentals-dashboard {
    margin: variables.$spacing-m auto;
    padding: variables.$spacing-m;
  }
}


