@use "src/styles/common/variables";


$primary-color: #ffffff;
$secondary-color: rgba(255, 255, 255, 0.6);
$active-color: #8a54ff;

.homepage-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: variables.$primary-color;
}


.homepage-header {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}


.category-tabs {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  justify-content: center;

  button {
    background: $secondary-color;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: rgba(255, 255, 255, 0.8);
    }

    &.active {
      background: variables.$button-color;
      color: white;
    }
  }
}

.subcategory-slider {
  display: flex;
  overflow-x: auto;
  gap: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  justify-content: center;
  

  .subcategory {
    background: $secondary-color;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    color: black;
    font-weight: bold;
    transition: background 0.3s;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.8);
    }

    &.active {
      background: variables.$button-color;
      color: white;
    }
  }
}

.image-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}


.image-part {
  margin-top: 1rem;
  width: 500px;
  align-items: center;
  border-radius: 15px;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0.8;

  &:hover {
    transform: scale(1.05);
  }


}

.rentals-dashboard {
  display: block;
  padding: variables.$spacing-xs;
  background-color: rgba(0, 0, 0, 0.75);
  // margin: variables.$spacing-xs;
  // margin-top: 80px;
  padding: variables.$spacing-s;
  margin: 40px auto;
  width: 90%;

  @include variables.mq-for-desktop {

    width: 80%;
  }

  h2 {
    margin-bottom: variables.$spacing-s;
    color: variables.$primary-color;
  }

  h3 {
    margin-bottom: variables.$spacing-s;
    margin-top: variables.$spacing-s;
    color: variables.$primary-color;
  }

  .rentals-dashboard-rentals {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, auto));
    justify-content: center;
    row-gap: variables.$spacing-xs;
    column-gap: variables.$spacing-xs;
    margin-bottom: variables.$spacing-xs;
    color: variables.$primary-color;
  }
}

.rentals-dashboard-rental {
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.rentals-dashboard-rental.isSelected {
  grid-area: 1 / 1 / 2 / 2; 
  z-index: 10;
  grid-column: 1 / -1;
}

.rentals-dashboard-rental.isSelected .menu {
  height: 100%;
}

.rentals-dashboard-rental.isSelected .menu img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@include variables.mq-for-tablet {
  .rentals-dashboard {
    margin: variables.$spacing-m auto;
    padding: variables.$spacing-m;
  }
}


.pagination-controls {
  width: 250px;
  position: relative;
  transform: translate(-50%);
  left: 50%;
  top: 20px;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 20px;
}

.pagination-controls button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.pagination-controls button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.pagination-controls span {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.web-store-search-wrap {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0px 45px;
  margin-bottom: variables.$spacing-xs;
  flex-direction: row;

  #web-store-category-sort-input {
    width: inherit;
    margin-left: variables.$spacing-xs;
    text-align: center;
    padding: 10px;
  }

  .web-store-search-icon {
    font-size: variables.$font-size-xl;
    color: variables.$button-color;
    // margin-right: variables.$spacing-xs;
    background: rgba(255, 255, 255, 0.8);
    height: 43px;
    width: 43px;
    padding: 10px;
    cursor: pointer;

    
  }

  .web-store-search-input-wrap {
    .empty-input-error {
      margin: 0;
    }
  }
}